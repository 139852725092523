import './modernizr-touch';

const galleryElems = document.querySelectorAll('.main-carousel');

if (galleryElems !== null) {
  for (let i=0, len = galleryElems.length; i < len; i++ ) {
    const galleryElem = galleryElems[i];

    // noinspection JSUnresolvedVariable
    new Flickity( galleryElem, {
      cellAlign: 'left',
      prevNextButtons: false,
      pageDots: true,
      wrapAround: true,
      draggable: Modernizr.touchevents,
      autoPlay: 4000
    });
  }

  window.addEventListener('load', function() {
    const elements = document.getElementsByClassName('main-carousel');
    for (let i = 0; i < elements.length; i++) {
      const flkty = Flickity.data(elements[i]);
      flkty.resize();
    }
  });

  $(window).on('load', function () {
    const elements = document.getElementsByClassName('main-carousel');
    for (let i = 0; i < elements.length; i++) {
      window.dispatchEvent(new Event('resize'));
    }
  });
}