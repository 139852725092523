import $ from "jquery";
import './helpers/responsive_nav';
import './helpers/squadup_modal';
import './helpers/flickity_gallery';
import './helpers/waypoint_manager';

import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import intersect from '@alpinejs/intersect'

window.Alpine = Alpine

Alpine.plugin(focus)
Alpine.plugin(intersect)
Alpine.start()

window.$ = $;