function CustomEvent(event, params) {
  params = params || { bubbles: false, cancelable: false, detail: undefined };
  const evt = document.createEvent('CustomEvent');
  evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
  return evt;
}

// noinspection JSValidateTypes
CustomEvent.prototype = window.Event.prototype;

// noinspection JSValidateTypes
window.CustomEvent = CustomEvent;

export { CustomEvent };