import $ from 'jquery';
import { CustomEvent } from './custom_event';

let eventId = 0;

$(function() {
  const modalSelector = '[data-modal]';
  const openModalSelector = '[data-open-modal]';
  const closeModalSelector = '[data-close-modal]';
  let whichModal = '';

  // Event or Cart Button Click
  $(openModalSelector).on('click', function(event) {
    whichModal = $(this).data('squadup');

    // Squad UP Event
    if(whichModal === 'event') {

      // Set eventId to SquadUP id
      eventId = $(this).data('event-id');

      window.squadup = Object.assign(window.squadup, { eventId, showCheckout: false });
      // Squad UP Cart
    } else if (whichModal === 'cart') {
      window.squadup = Object.assign(window.squadup, {
        showCheckout: true
      });
    } else if (whichModal === "none") {
      // Set event id to Craft entry id
      eventId = $(this).data('event-id');
    }

    const openButton = $(event.target);
    const modal = modalForOpenButton(openButton);
    closeButtonForModal(modal);

    modal.attr('aria-hidden', false);
    openButton.addClass('active-button');

    // Programmatically launch the SquadUP embed widget
    if(whichModal === 'event' || whichModal === 'cart') {
      document.dispatchEvent(new CustomEvent('createSquadupEmbed'));
    }

    $('body').addClass('modal--open');
    const offset = document.body.scrollTop;
    document.body.style.top = (offset * -1) + 'px';

    // Scroll to top on add to cart
    document.addEventListener("shoppingCartUpdated", function (_) {
      console.log('shoppingCartUpdated');
      $('.modal').scrollTop(0);
    }, false);
  });

  function closeModal(modal) {
    const openButton = openButtonForModal(modal);

    modal.attr('aria-hidden', true);
    const offset = parseInt(document.body.style.top, 10);
    $('body').removeClass('modal--open');
    $('.active-button').focus();

    openButton.removeClass('active-button');
    document.body.scrollTop = (offset * -1);

    // allow stick nav to seat properly after modal closes
    setTimeout(function(){
      Waypoint.refreshAll()
    }, 100)

    // Destroy the embed object anytime the modal is closed
    document.dispatchEvent(new CustomEvent('destroySquadupEmbed'));
  }

  $(closeModalSelector).on('click', function(event) {
    const closeButton = $(event.target);
    const modal = modalForCloseButton(closeButton);

    closeModal(modal);
  });

  $(document).on('keyup', function(event) {
    const ESCAPE_KEY_CODE = 27;

    if(event.keyCode === ESCAPE_KEY_CODE) {
      const modal = $(modalSelector + '[aria-hidden=false]');
      closeModal(modal);
    }
  });

  function openButtonForModal(modal) {
    return $('[data-open-modal=' + modal.data('modal') + ']');
  }

  function closeButtonForModal(modal) {
    return modal.find($(closeModalSelector));
  }

  function modalForOpenButton(openButton) {
    return $('[data-modal=' + openButton.data('open-modal') + ']');
  }

  function modalForCloseButton(closeButton) {
    return closeButton.closest($(modalSelector));
  }
});