import $ from 'jquery';

$(window).resize(function() {
  const more = document.getElementById("js-navigation-more");
  const navSubmenu = $("#js-navigation-more .submenu .submenu");

  if ($(more).length > 0) {
    const windowWidth = $(window).width();
    const moreLeftSideToPageLeftSide = $(more).offset().left;
    const moreLeftSideToPageRightSide = windowWidth - moreLeftSideToPageLeftSide;

    if (moreLeftSideToPageRightSide < 330) {
      navSubmenu.removeClass("fly-out-right");
      navSubmenu.addClass("fly-out-left");
    }

    if (moreLeftSideToPageRightSide > 330) {
      navSubmenu.removeClass("fly-out-left");
      navSubmenu.addClass("fly-out-right");
    }
  }
});

$(document).ready(function() {
  let jsNavMenu = $("#js-navigation-menu");
  const menuToggle = $("#js-mobile-menu").unbind();
  jsNavMenu.removeClass("show");

  menuToggle.on("click", function(e) {
    e.preventDefault();
    $(".nav-icon").toggleClass("open");

    jsNavMenu.slideToggle(function(){
      if(jsNavMenu.is(":hidden")) {
        jsNavMenu.removeAttr("style");
      }
    });
  });

  $(".hover-menu-toggle").click(function() {
      $(this).toggleClass('closed');
    }
  );

});

$(document).ready(function () {
  const filterToggle = $(".filter--toggle");
  let activeToggle = null;

  filterToggle.on('click', function () {
    const clickedElement = $(this);

    if (clickedElement.attr('id') === activeToggle) {
      clickedElement.removeClass('filter--open');
      activeToggle = null;
    } else {
      if (activeToggle) {
        $('#' + activeToggle).removeClass('filter--open');
      }
      clickedElement.addClass('filter--open');
      activeToggle = clickedElement.attr('id');
    }
  });

  filterToggle.mouseover(function () {
    $(this).addClass("active");
  });

  filterToggle.mouseout(function () {
    $(this).removeClass("active");
  });

  $("#search-type-filter").on("touchstart", function () {
    $(this).addClass("active");
    $("#search-lang-filter, #search-time-filter, #search-day-filter").removeClass("active");
  });

  $("#search-time-filter").on("touchstart", function () {
    $(this).addClass("active");
    $("#search-lang-filter, #search-type-filter, #search-day-filter").removeClass("active");
  });

  $("#search-lang-filter").on("touchstart", function () {
    $(this).addClass("active");
    $("#search-type-filter, #search-time-filter, #search-day-filter").removeClass("active");
  });

  $("#search-day-filter").on("touchstart", function () {
    $(this).addClass("active");
    $("#search-lang-filter, #search-time-filter, #search-type-filter").removeClass("active");
  });

});

var el = document.querySelector('#search-featured-filter');

if (el) {
  el.addEventListener('click', function(){
    for(var i = 0; i < el.length; i++) {
      el[i].classList.toggle('active');
    }
  });

  el.onclick = function() {
    el.classList.toggle('active');
  }
}


$(document).ready(function () {
  let body = $('body');
  $(".filter--toggle--main, .apply").on("click", function () {
    $('.festival--filter--wrap').toggleClass("main--filter--open");
    body.toggleClass('overflow--hidden');
    body.toggleClass('mobile--nav--open');
    $('html, body').animate({
      scrollTop: ($('.filter--toggle--main').offset().top)
    }, 1);
  });

});